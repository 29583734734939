var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  env: 'development',
  apiUrl: 'http://localhost:8080', // Local seulement
  authConfig: {
    devMode: true
  }
})

<template>
  <v-container>
    <v-snackbar v-model="errorSnackBar" color="error" dark timeout="8000">Une erreur est survenue</v-snackbar>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select v-model="selectedService" :items="services" hide-details label="Service" outlined/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="selectedAction" :disabled="!selectedService" :items="actions" hide-details
                          label="Action"
                          outlined/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="selectedCompanyUuid" hide-details label="UUID Société" outlined/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="selectedBranchUuid" :disabled="!selectedCompanyUuid" hide-details
                              label="UUID Agence" outlined/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-data-table
              :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
              :items-per-page="50"
              :headers="tableHeaders"
              :items="tableItems"
              :loading="loading"
              :options.sync="tableOptions"
              :server-items-length="tableTotalItems"
          >
            <template v-slot:item.branchUuid="{ item }">
              <y-branch-label :value="item.branchUuid"/>
            </template>
            <template v-slot:item.companyUuid="{ item }">
              <y-company-label :value="item.companyUuid"/>
            </template>
            <template v-slot:item.lastDoneAt="{ item }">
              {{ item.lastDoneAt | moment('YYYY-MM-DD HH:mm') }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    loading: false,

    services: [],
    selectedService: null,
    actions: [],
    selectedAction: null,

    selectedCompanyUuid: "",
    selectedBranchUuid: "",

    errorSnackBar: null,

    tableOptions: {
      sortBy: ['lastDoneAt'],
      sortDesc: [true]
    },
    tableHeaders: [
      {text: "Service", value: "serviceName", sortable: false},
      {text: "Action", value: "actionKey"},
      {text: "Agence", value: "branchUuid"},
      {text: "Sociéte", value: "companyUuid"},
      {text: "Clé période", value: "periodKey"},
      {text: "Compteur", value: "counter"},
      {text: "Dernier usage", value: "lastDoneAt"}
    ],
    tableItems: [],
    tableTotalItems: 0,
  }),
  mounted() {
    this.fetchServices();
  },
  watch: {
    selectedService(newVal) {
      if (newVal) {
        this.tableOptions.page = 1;
        this.fetchActions();
      } else {
        this.actions = [];
        this.selectedAction = null;
      }
    },
    selectedAction() {
      this.tableOptions.page = 1;
      this.fetchUsages();
    },
    selectedCompanyUuid(newVal) {
      if (newVal === "") {
        this.selectedBranchUuid = null;
      }
      this.tableOptions.page = 1;
      this.fetchUsages();
    },
    selectedBranchUuid() {
      this.tableOptions.page = 1;
      this.fetchUsages();
    },
    tableOptions: {
      handler() {
        if (this.selectedService && this.selectedAction)
        this.fetchUsages();
      },
      deep: true,
    }
  },
  methods: {
    fetchServices() {
      this.loading = true;
      this.errorSnackBar = false;
      this.$http.get(this.$config.apiUrl + '/v1/services')
          .then((r) => this.services = r.data)
          .catch(() => this.errorSnackBar = true)
          .finally(() => this.loading = false);
    },
    fetchActions() {
      this.loading = true;
      this.errorSnackBar = false;
      this.$http.get(this.$config.apiUrl + '/v1/services/' + this.selectedService + "/actions")
          .then((r) => this.actions = r.data)
          .catch(() => this.errorSnackBar = true)
          .finally(() => this.loading = false);
    },
    fetchUsages() {

      this.loading = true;
      this.errorSnackBar = false;

      const {sortBy, sortDesc, page, itemsPerPage} = this.tableOptions;

      let url = this.$config.apiUrl + '/v1/services/' + this.selectedService;
      if (this.selectedCompanyUuid) url += "/companies/" + this.selectedCompanyUuid;
      if (this.selectedBranchUuid) url += "/branches/" + this.selectedBranchUuid;
      url += "/actions/" + this.selectedAction + "/usages";

      let params = {page: (page - 1), size: itemsPerPage};
      if (sortBy.length) {
        params.sort = sortBy[0] + "," + (sortDesc[0] ? 'desc' : 'asc')
      }

      this.$http.get(url, {params: params})
          .then((r) => {
            this.tableItems = r.data.content;
            this.tableTotalItems = r.data.totalElements;
          })
          .catch(() => this.errorSnackBar = true)
          .finally(() => this.loading = false);
    }
  }
}
</script>
